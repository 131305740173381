<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <v-form ref="form" class="w-100">
            <div class="edit-modal-container">
              <div class="row d-flex justify-center align-center w-100">
                <h2>Edit {{ field.name }} field</h2>
              </div>
              <div class="col" v-if="specialForm">
                <div class="row d-flex justify-center align-center mt-5 mb-5">
                  <label for=""> New {{ field.name }}: </label>
                  <v-text-field
                    v-if="field.name == 'Phone Number'"
                    :label="field.name"
                    v-model="newContentField"
                    :rules="[rules.required]"
                  />
                  <v-text-field
                    v-if="field.name == 'Email'"
                    :label="field.name"
                    v-model="newContentField"
                    :rules="[rules.required, rules.email]"
                  />
                </div>
                <div class="row d-flex justify-center align-center mt-5 mb-5">
                  <v-btn
                    outlined
                    color="primary"
                    dark
                    @click="generateConfirmationCode"
                    >Generate confirmation code
                  </v-btn>
                </div>
                <div
                  class="row d-flex justify-center align-center mt-5 mb-5"
                  v-if="generationCode"
                >
                  <label for=""> Confirmation Code: </label>
                  <v-text-field
                    label="Confirmation Code"
                    v-model="confirmationCode"
                    :rules="[rules.required]"
                  />
                </div>
              </div>

              <div
                class="row d-flex justify-center align-center mt-5 mb-5"
                v-if="!specialForm"
              >
                <label for=""> {{ field.name }}: </label>
                <v-text-field
                  :label="field.name"
                  v-model="newContentField"
                  :rules="[rules.required]"
                  v-if="
                    field.name == 'Name' ||
                    field.name == 'Last Name' ||
                    field.name == 'Position In The Organization'
                  "
                />
                <v-autocomplete
                  :items="timeZones"
                  label="Time zone"
                  :rules="[rules.required]"
                  v-model="newContentField"
                  v-if="field.name == 'Time Zone'"
                >
                </v-autocomplete>
                <v-select
                  item-value="id"
                  item-text="name"
                  :items="roles"
                  label="Roles"
                  :rules="[rules.required]"
                  v-model="newContentField"
                  v-if="field.name == 'Role'"
                >
                </v-select>
                <v-switch
                  v-if="field.name == 'Condition'"
                  v-model="newContentField"
                  :label="`${
                    newContentField ? 'User Enabled' : 'User Disabled'
                  } `"
                ></v-switch>
              </div>

              <div class="row d-flex justify-end align-end w-100">
                <v-btn color="error" dark @click="$emit('close')" class="m-2">
                  Cancel
                </v-btn>
                <v-btn
                  v-if="!specialForm"
                  color="primary"
                  dark
                  @click="handleSubmit()"
                  class="m-2"
                  elevation="2"
                >
                  Save
                </v-btn>
                <v-btn
                  v-if="specialForm"
                  color="primary"
                  :disabled="!generationCode"
                  :dark="generationCode"
                  @click="handleSubmit()"
                  class="m-2"
                  elevation="2"
                >
                  Save
                </v-btn>
              </div>
            </div>
          </v-form>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import form from "@/mixins/form";

export default {
  name: "edit-mode",
  mixins: [form],

  props: {
    field: {
      type: Object,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      newContentField: null,
      confirmationCode: "",
      timeZones: [],
      roles: [],
      specialForm: false,
      generationCode: false,
    };
  },
  methods: {
    getTimeZones() {
      this.$store
        .dispatch("timezone/getTimezones")
        .then((data) => {
          this.timeZones = data;
        })
        .catch((err) => console.error(err));
    },
    getRoles() {
      this.$store
        .dispatch("role/getRoles")
        .then((data) => (this.roles = data))
        .catch((err) => console.error(err));
    },
    setName() {
      if (this.$route.params.user) {
        this.$store
          .dispatch("userV1/editUser", {
            id: this.userId,
            name: this.newContentField,
          })
          .then((data) => {
            this.$toast.success("Name has been modified");
            this.$emit("updated", data);
            this.$emit("close");
          })
          .catch((err) => console.error(err));
      } else {
        this.$store
          .dispatch("userV1/editMyInfo", {
            name: this.newContentField,
          })
          .then((data) => {
            this.$toast.success("Name has been modified");
            this.$emit("updated", data);
            this.$emit("close");
          })
          .catch((err) => console.error(err));
      }
    },
    setLastName() {
      if (this.$route.params.user) {
        this.$store
          .dispatch("userV1/editUser", {
            id: this.userId,
            lastName: this.newContentField,
          })
          .then((data) => {
            this.$toast.success("Last Name has been modified");
            this.$emit("updated", data);
            this.$emit("close");
          })
          .catch((err) => console.error(err));
      } else {
        this.$store
          .dispatch("userV1/editMyInfo", {
            lastName: this.newContentField,
          })
          .then((data) => {
            this.$toast.success("Last Name has been modified");
            this.$emit("updated", data);
            this.$emit("close");
          })
          .catch((err) => console.error(err));
      }
    },
    setRole() {
      this.$store
        .dispatch("userV1/editUser", {
          id: this.userId,
          roleId: this.newContentField,
        })
        .then((data) => {
          this.$toast.success("Role has been modified");
          this.$emit("updated", data);
          this.$emit("close");
        })
        .catch((err) => console.error(err));
    },
    setTz() {
      if (this.$route.params.user) {
        this.$store
          .dispatch("userV1/editUser", {
            id: this.userId,
            tz: this.newContentField,
          })
          .then((data) => {
            this.$toast.success("Time Zone has been modified");
            this.$emit("updated", data);
            this.$emit("close");
          })
          .catch((err) => console.error(err));
      } else {
        this.$store
          .dispatch("userV1/editMyInfo", {
            tz: this.newContentField,
          })
          .then((data) => {
            this.$toast.success("Time Zone has been modified");
            this.$emit("updated", data);
            this.$emit("close");
          })
          .catch((err) => console.error(err));
      }
    },
    setPhoneNumber() {
      this.$store
        .dispatch("userV1/changePhone", {
          confirmationCode: this.confirmationCode,
          phoneNumber: this.newContentField,
        })
        .then((data) => {
          this.$toast.success("Phone Number has been modified");
          this.$emit("updated", data);
          this.$emit("close");
        })
        .catch((err) => console.error(err));
    },
    setEmail() {
      this.$store
        .dispatch("userV1/changeEmail", {
          confirmationCode: this.confirmationCode,
          email: this.newContentField,
        })
        .then((data) => {
          this.$toast.success("Email has been modified");
          this.$emit("updated", data);
          this.$emit("close");
        })
        .catch((err) => console.error(err));
    },
    generateConfirmationCode() {
      let protocol;
      if (this.field.name == "Phone Number") {
        protocol = "sms";
      }
      if (this.field.name == "Email") {
        protocol = "email";
      }
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch("userV1/generateConfirmationCode", {
            protocol: protocol,
            endpoint: this.newContentField,
          })
          .then(() => {
            this.$toast.success(`Your confirmation code has been sent`);
            this.generationCode = true;
          })
          .catch((err) => console.error(err));
      }
    },
    enableUser() {
      this.$store
        .dispatch("userV1/activateUser", {
          id: this.userId,
        })
        .then((data) => {
          this.$toast.success("User has been enabled");
          this.$emit("updated", data);
          this.$emit("close");
        })
        .catch((err) => {
          console.error(err);
        });
    },
    disableUser() {
      this.$store
        .dispatch("userV1/deactivateUser", {
          id: this.userId,
        })
        .then((data) => {
          this.$toast.success("User has been disabled");
          this.$emit("updated", data);
          this.$emit("close");
        })
        .catch((err) => {
          console.error(err);
        });
    },
    setPosition() {
      if (this.$route.params.user) {
        this.$store
          .dispatch("userV1/editUser", {
            id: this.userId,
            positionInTheOrganization: this.newContentField,
          })
          .then((data) => {
            this.$emit("updated", data);
            this.$emit("close");
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        this.$store
          .dispatch("userV1/editMyInfo", {
            positionInTheOrganization: this.newContentField,
          })
          .then((data) => {
            this.$emit("updated", data);
            this.$emit("close");
          })
          .catch((err) => console.error(err));
      }
    },
    handleSubmit() {
      if (this.$refs.form.validate()) {
        switch (this.field.name) {
          case "Name":
            this.setName();
            break;
          case "Last Name":
            this.setLastName();
            break;
          case "Role":
            this.setRole();
            break;
          case "Time Zone":
            this.setTz();
            break;
          case "Phone Number":
            this.setPhoneNumber();
            break;
          case "Email":
            this.setEmail();
            break;
          case "Condition":
            if (this.newContentField) {
              this.enableUser();
            } else {
              this.disableUser();
            }
            break;
          case "Position In The Organization":
            this.setPosition();
            break;
          default:
            break;
        }
      }
    },
  },
  mounted() {
    this.newContentField = this.field.content;
    if (this.field.name == "Time Zone") {
      this.getTimeZones();
    }
    if (this.field.name == "Role") {
      this.getRoles();
    }
    if (this.field.name == "Email" || this.field.name == "Phone Number") {
      this.specialForm = true;
    }
  },
};
</script>

<style lang='sass'>
@import '@/assets/sass/modals/modal.sass'

.edit-modal-container
  width: 45%
  height: 450px
  margin: 0px auto
  padding: 20px 30px
  background-color: #fff
  border-radius: 2px
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33)
  transition: all 0.3s ease
  font-family: Helvetica, Arial, sans-serif
  display: flex
  flex-direction: column
  align-items: center
  justify-content: space-between
  label
    font-weight: 600
    margin-right: 20px

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter
  opacity: 0

.modal-leave-active
  opacity: 0

.modal-enter .edit-modal-container,
.modal-leave-active .edit-modal-container
  -webkit-transform: scale(1.1)
  transform: scale(1.1)

.upload-file
  color: grey
  font-size: 1.35rem
  border-radius: 5px
  cursor: pointer

input[type=file]
  display: none
</style>