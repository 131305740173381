<template>
  <div class="container-fluid" v-if="user">
    <div class="row d-flex justify-center align-center">
      <div class="col">
        <div class="row d-flex justify-center align-center">
          <v-card class="tgc-header-card w-88" color="primary" dark>
            <div class="row d-flex justify-center align-center">
              <v-avatar
                size="200"
                color="white"
                class="account-avatar"
                v-if="user.profilePhoto"
              >
                <img
                  :src="user.profilePhoto"
                  alt="alt"
                  :class="{ 'account-image': editable }"
                />
                <v-btn
                  class="mx-2 account-image-btn"
                  fab
                  dark
                  small
                  color="rgb(35, 197, 119, 0.2)"
                  v-if="editable"
                  @click="showProfilePhoto = true"
                >
                  <v-icon dark> mdi-camera-plus-outline </v-icon>
                </v-btn>
              </v-avatar>
              <v-avatar
                size="200"
                color="green"
                class="account-avatar"
                v-if="!user.profilePhoto"
              >
                <span
                  class="account-avatar-initials"
                  :class="{ 'account-image': editable }"
                  >{{ initials }}</span
                >
                <v-btn
                  class="mx-2 account-span-btn"
                  fab
                  dark
                  small
                  color="rgb(35, 197, 119, 0.2)"
                  v-if="editable"
                  @click="showProfilePhoto = true"
                >
                  <v-icon dark> mdi-camera-plus-outline </v-icon>
                </v-btn>
              </v-avatar>
            </div>
          </v-card>
        </div>
        <tgl-card-content>
          <div class="account-spacer"></div>
          <div class="account-name row d-flex justify-center align-center">
            <h1>
              {{ user.name }}
              <v-btn
                fab
                dark
                x-small
                color="primary"
                @click="setField('Name', user.name)"
                v-if="editable"
              >
                <v-icon dark> mdi-pencil-outline </v-icon>
              </v-btn>
              {{ user.lastName }}
              <v-btn
                fab
                dark
                x-small
                color="primary"
                @click="setField('Last Name', user.lastName)"
                v-if="editable"
              >
                <v-icon dark> mdi-pencil-outline </v-icon>
              </v-btn>
            </h1>
          </div>
          <div class="row d-flex justify-center align-center">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
              <div class="row d-flex justify-center align-center">
                <div
                  class="
                    col-4
                    account-label
                    d-flex
                    flex-column
                    align-center
                    justify-center
                  "
                >
                  <label>Phone Number</label>
                </div>
                <div
                  class="
                    col-1
                    account-label
                    d-flex
                    flex-column
                    align-center
                    justify-center
                  "
                >
                  <label>:</label>
                </div>
                <div
                  class="
                    col-6
                    account-info
                    d-flex
                    flex-column
                    align-start
                    justify-center
                  "
                >
                  <p>{{ user.phoneNumber }}</p>
                </div>
                <div class="col-1" v-if="editable && !userExist">
                  <v-btn
                    fab
                    dark
                    x-small
                    color="primary"
                    @click="setField('Phone Number', user.phoneNumber)"
                  >
                    <v-icon dark> mdi-pencil-outline </v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
              <div class="row d-flex justify-center align-center">
                <div
                  class="
                    col-4
                    account-label
                    d-flex
                    flex-column
                    align-end
                    justify-center
                  "
                >
                  <label>Email</label>
                </div>
                <div
                  class="
                    col-1
                    account-label
                    d-flex
                    flex-column
                    align-center
                    justify-center
                  "
                >
                  <label>:</label>
                </div>
                <div
                  class="
                    col-6
                    account-info
                    d-flex
                    flex-column
                    align-start
                    justify-center
                  "
                >
                  <p>{{ user.email }}</p>
                </div>
                <div class="col-1" v-if="editable && !userExist">
                  <v-btn
                    fab
                    dark
                    x-small
                    color="primary"
                    @click="setField('Email', user.email)"
                  >
                    <v-icon dark> mdi-pencil-outline </v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
              <div class="row d-flex justify-center align-center pr-4">
                <div
                  class="
                    col-4
                    account-label
                    d-flex
                    flex-column
                    align-end
                    justify-center
                  "
                >
                  <label>Time Zone</label>
                </div>
                <div
                  class="
                    col-1
                    account-label
                    d-flex
                    flex-column
                    align-center
                    justify-center
                  "
                >
                  <label>:</label>
                </div>
                <div
                  class="
                    col-6
                    account-info
                    d-flex
                    flex-column
                    align-start
                    justify-center
                  "
                >
                  <p>{{ user.tz }}</p>
                </div>
                <div class="col-1" v-if="editable">
                  <v-btn
                    fab
                    dark
                    x-small
                    color="primary"
                    @click="setField('Time Zone', user.tz)"
                  >
                    <v-icon dark> mdi-pencil-outline </v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
              <div class="row d-flex justify-center align-center">
                <div
                  class="
                    col-4
                    account-label
                    d-flex
                    flex-column
                    align-end
                    justify-center
                  "
                >
                  <label>Organization</label>
                </div>
                <div
                  class="
                    col-1
                    account-label
                    d-flex
                    flex-column
                    align-center
                    justify-center
                  "
                >
                  <label>:</label>
                </div>
                <div
                  class="
                    col-7
                    account-info
                    d-flex
                    flex-column
                    align-start
                    justify-center
                  "
                >
                  <p>{{ user.organization }}</p>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
              <div class="row d-flex justify-center align-center">
                <div
                  class="
                    col-4
                    account-label
                    d-flex
                    flex-column
                    align-end
                    justify-center
                  "
                >
                  <label>Role</label>
                </div>
                <div
                  class="
                    col-1
                    account-label
                    d-flex
                    flex-column
                    align-center
                    justify-center
                  "
                >
                  <label>:</label>
                </div>
                <div
                  class="
                    col-6
                    account-info
                    d-flex
                    flex-column
                    align-start
                    justify-center
                  "
                >
                  <p>{{ user.roleName }}</p>
                </div>
                <div class="col-1" v-if="editable && userExist">
                  <v-btn
                    fab
                    dark
                    x-small
                    color="primary"
                    @click="setField('Role', user.roleId)"
                  >
                    <v-icon dark> mdi-pencil-outline </v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
              <div class="row d-flex justify-center align-center pr-4">
                <div
                  class="
                    col-4
                    account-label
                    d-flex
                    flex-column
                    align-end
                    justify-center
                  "
                >
                  <label>Condition</label>
                </div>
                <div
                  class="
                    col-1
                    account-label
                    d-flex
                    flex-column
                    align-center
                    justify-center
                  "
                >
                  <label>:</label>
                </div>
                <div
                  class="
                    col-6
                    account-info
                    d-flex
                    flex-column
                    align-start
                    justify-center
                  "
                >
                  <p>{{ userActive }}</p>
                </div>
                <div class="col-1" v-if="editable && userExist">
                  <v-btn
                    fab
                    dark
                    x-small
                    color="primary"
                    @click="setField('Condition', user.active)"
                  >
                    <v-icon dark> mdi-pencil-outline </v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
              <div class="row d-flex justify-center align-center">
                <div
                  class="
                    col-4
                    account-label
                    d-flex
                    flex-column
                    align-end
                    justify-center
                  "
                >
                  <label>Position</label>
                </div>
                <div
                  class="
                    col-1
                    account-label
                    d-flex
                    flex-column
                    align-center
                    justify-center
                  "
                >
                  <label>:</label>
                </div>
                <div
                  class="
                    col-6
                    account-info
                    d-flex
                    flex-column
                    align-start
                    justify-center
                  "
                >
                  <p>
                    {{
                      user.positionInTheOrganization
                        ? user.positionInTheOrganization
                        : "dont't seted yet"
                    }}
                  </p>
                </div>
                <div class="col-1" v-if="editable">
                  <v-btn
                    fab
                    dark
                    x-small
                    color="primary"
                    @click="
                      setField(
                        'Position In The Organization',
                        user.positionInTheOrganization
                      )
                    "
                  >
                    <v-icon dark> mdi-pencil-outline </v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-end mt-5 mb-5 mr-5" v-if="editable">
            <v-btn color="primary" @click="$emit('disableEditMode')"
              >Disable Edit Mode</v-btn
            >
          </div>
        </tgl-card-content>
      </div>
    </div>
    <edit-modal
      v-if="showEditModal"
      @close="showEditModal = false"
      :field="modalField"
      @updated="userUpdated"
      :userId="user.id"
    ></edit-modal>
    <profile-photo-modal
      v-if="showProfilePhoto"
      @close="showProfilePhoto = false"
      @updatePhoto="updatePhoto"
    ></profile-photo-modal>
    <password-modal
      v-if="changePassword"
      @close="$emit('close')"
      :userId="user.id"
    ></password-modal>
  </div>
</template>

<script>
import TglCardContent from "@/components/Cards/TglCardContent.vue";
import EditModal from "@/components/Modal/Account/editModal.vue";
import ProfilePhotoModal from "@/components/Modal/Account/ProfilePhotoModal.vue";
import PasswordModal from "@/components/Modal/Account/PasswordModal.vue";
export default {
  components: { TglCardContent, EditModal, ProfilePhotoModal, PasswordModal },
  props: {
    editable: {
      type: Boolean,
      required: true,
    },
    changePassword: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      user: null,
      roles: [],
      showEditModal: false,
      showProfilePhoto: false,
      modalField: {
        name: "",
        content: null,
      },
    };
  },
  computed: {
    roleName() {
      return this.roles.find((rol) => {
        rol = this.user.roleId;
      });
    },
    userActive() {
      return this.user.active ? "Active" : "Inactive";
    },
    userExist() {
      return this.$route.params.user ? true : false;
    },
    initials() {
      let nameInitial = "";
      let lastNameInitial = "";
      if (this.user.name) {
        nameInitial = this.user.name.substring(0, 1);
      }
      if (this.user.lastName) {
        lastNameInitial = this.user.lastName.substring(0, 1);
      }
      return `${nameInitial} ${lastNameInitial}`;
    },
  },
  methods: {
    getUser() {
      this.$store
        .dispatch("userV1/getUser", this.$route.params.user)
        .then((data) => {
          this.user = data;
          this.$emit("user", {
            name: this.user.name,
            lastName: this.user.lastName,
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getMyInfo() {
      this.$store
        .dispatch("userV1/getMyInfo")
        .then((data) => {
          this.user = data;
          this.$emit("user", {
            name: this.user.name,
            lastName: this.user.lastName,
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getRoles() {
      this.$store
        .dispatch("role/getRoles")
        .then((data) => (this.roles = data))
        .catch((err) => console.error(err));
    },
    setField(name, content) {
      this.modalField.name = name;
      this.modalField.content = content;
      this.showEditModal = true;
    },
    userUpdated(user) {
      this.user = user;
    },
    updatePhoto(profilePhotoId) {
      this.$store
        .dispatch("userV1/editUser", {
          id: this.user.id,
          profilePhotoId: profilePhotoId,
        })
        .then((data) => {
          this.user.profilePhoto = data.profilePhoto;
        })
        .catch((err) => console.error(err));
    },
  },
  mounted() {
    if (this.$route.params.user) {
      this.getUser();
    } else {
      this.getMyInfo();
    }
  },
};
</script>

<style lang="sass">
@import '@/assets/scss/md/_colors'

.account-avatar
  position: relative
  z-index: 1
  top: -70px

.account-label
  padding-right: 10px
  margin: 10px 0 10px 0
  label
    margin: 0
    font-size: 1rem
    font-weight: 500 !important

.account-avatar-initials
  font-size: 4rem
  color: white
  font-weight: 500

.account-info
  padding: 0
  margin: 10px 0 10px 0
  p
    font-size: 1rem
    margin: 0

.account-name
  margin-bottom: 20px
  h1
    font-size: 1.8rem !important
    font-weight: 300 !important

.acount-content
  height: 600px
  width: 60%

.tgc-header-card
  top: 70px
  height: 150px
  z-index: 1
  background: linear-gradient (60deg, $mainTheme-primary, $mainTheme-primary) !important
  box-shadow: 0 12px 20px -10px rgb(35 197 119 / 28%), 0 4px 20px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(35 197 119 / 20%) !important
  h1
    font-size: 1.25rem !important
    font-weight: 500
    letter-spacing: 0.0125em !important

.w-88
  width: 88%
.account-avatar
  position: relative
  z-index: 1
  top: -70px
  display: flex
  flex-direction: column
.account-image
  position: relative
  top: 20px
.account-image-btn
  position: relative
  z-index: 2
  top: -40px
  left: 50px
.account-span-btn
  position: relative
  z-index: 2
  top: 15px
  left: 50px
</style>