<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="profile-photo-modal-container">
            <div class="row d-flex justify-center align-center w-100">
              <h2>Select profile photo</h2>
            </div>

            <div
              class="
                row
                d-flex
                justify-center
                align-center
                mt-5
                mb-5
                file-container
              "
            >
              <div class="col">
                <div class="row d-flex justify-center align-center w-100">
                  <label for="upload-input" class="upload-file">
                    <v-icon class="mb-2">mdi-camera-plus-outline</v-icon>
                    Upload image
                  </label>
                  <input
                    id="upload-input"
                    type="file"
                    accept="image/*"
                    @change="selectedFile"
                  />
                </div>
                <div class="row d-flex justify-center align-center w-100 mt-5">
                  <v-avatar size="200" color="white" v-if="imageCompressed">
                    <img :src="imageCompressed.full_path" />
                  </v-avatar>
                </div>
              </div>
            </div>

            <div class="row d-flex justify-end align-end w-100">
              <v-btn color="error" dark @click="$emit('close')" class="m-2">
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                dark
                @click="updateProfilePhoto"
                class="m-2"
              >
                Save profile photo
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "profile-photo-modal",
  data() {
    return {
      maxWidth: 500,
      maxHeight: 500,
      mimeType: "image/jpeg",
      quality: 0.8,
      imageCompressed: null,
    };
  },
  methods: {
    selectedFile(ev) {
      const file = ev.target.files[0]; // get the file
      const blobURL = URL.createObjectURL(file);
      const img = new Image();
      img.src = blobURL;
      img.onerror = () => {
        URL.revokeObjectURL(this.src);
        // Handle the failure properly
        console.log("Cannot load image");
      };
      img.onload = () => {
        URL.revokeObjectURL(this.src);
        const [newWidth, newHeight] = this.calculateSize(
          img,
          this.maxWidth,
          this.maxHeight
        );
        const canvas = document.createElement("canvas");
        canvas.width = newWidth;
        canvas.height = newHeight;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, newWidth, newHeight);
        canvas.toBlob(
          (blob) => {
            // Handle the compressed image. es. upload or save in local state
            this.uploadProfilePhoto(blob);
          },
          this.mimeType,
          this.quality
        );
      };
    },
    calculateSize(img, maxWidth, maxHeight) {
      let width = img.width;
      let height = img.height;

      // calculate the width and height, constraining the proportions
      if (width > height) {
        if (width > maxWidth) {
          height = Math.round((height * maxWidth) / width);
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width = Math.round((width * maxHeight) / height);
          height = maxHeight;
        }
      }
      return [width, height];
    },
    uploadProfilePhoto(blob) {
      const fd = new FormData();
      fd.append("file", blob);
      fd.append("public", true);
      this.$store
        .dispatch("account/uploadProfilePhoto", fd)
        .then((data) => {
          this.imageCompressed = data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    updateProfilePhoto() {
      this.$emit("updatePhoto", this.imageCompressed.id);
      this.$emit("close");
    },
  },
};
</script>

<style lang="sass">
@import '@/assets/sass/modals/modal.sass'

.profile-photo-modal-container
  width: 45%
  height: 50%
  margin: 0px auto
  padding: 20px 30px
  background-color: #fff
  border-radius: 2px
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33)
  transition: all 0.3s ease
  font-family: Helvetica, Arial, sans-serif
  display: flex
  flex-direction: column
  align-items: center

.file-container
  height: 60%
  width: 50%
/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter
  opacity: 0

.modal-leave-active
  opacity: 0

.modal-enter .profile-photo-modal-container,
.modal-leave-active .profile-photo-modal-container
  -webkit-transform: scale(1.1)
  transform: scale(1.1)

.upload-file
  color: grey
  font-size: 1.35rem
  border-radius: 5px
  cursor: pointer

input[type=file]
  display: none
</style>