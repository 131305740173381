<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <v-form ref="form" class="w-100" autocomplete="off">
            <div class="password-modal-container">
              <div class="row d-flex justify-center align-center w-100">
                <h2>Edit password</h2>
              </div>
              <div class="col w-75">
                <div class="row d-flex justify-center align-center mt-10 mb-5">
                  <div class="col-5 d-flex flex-column align-end">
                    <label for=""> Password: </label>
                  </div>
                  <div class="col-7">
                    <v-text-field
                      v-model="password"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      label="Password"
                      counter
                      :rules="[rules.required, rules.password]"
                      @click:append="showPassword = !showPassword"
                    ></v-text-field>
                  </div>
                </div>
                <div class="row d-flex justify-center align-center mt-5 mb-5">
                  <div class="col-5 d-flex flex-column align-end">
                    <label for=""> Confirm Password: </label>
                  </div>
                  <div class="col-7">
                    <v-text-field
                      v-model="confirmPassword"
                      :append-icon="
                        showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                      "
                      :type="showConfirmPassword ? 'text' : 'password'"
                      label="Password"
                      counter
                      :rules="[
                        (value) => rules.passwordConfirm(value, password),
                        rules.required,
                      ]"
                      @click:append="showConfirmPassword = !showConfirmPassword"
                    ></v-text-field>
                  </div>
                </div>
              </div>

              <div class="row d-flex justify-end align-end w-100">
                <v-btn color="error" dark @click="$emit('close')" class="m-2">
                  Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  dark
                  @click="handleSubmit()"
                  class="m-2"
                  elevation="2"
                >
                  Save
                </v-btn>
              </div>
            </div>
          </v-form>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import form from "@/mixins/form";

export default {
  components: {},
  mixins: [form],
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      password: "",
      confirmPassword: "",
      showPassword: false,
      showConfirmPassword: false,
    };
  },
  methods: {
    handleSubmit() {
      if (this.$refs.form.validate()) {
        this.changePassword();
      }
    },
    changePassword() {
      this.$store
        .dispatch("userV1/editUser", {
          id: this.userId,
          password: this.password,
          confirmPassword: this.confirmPassword,
        })
        .then((data) => {
          if (data) {
            this.$toast.success("Your password has been modified");
            this.$emit("close");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style scoped lang="sass">
@import '@/assets/sass/modals/modal.sass'
.password-modal-container
  width: 45%
  height: 450px
  margin: 0px auto
  padding: 20px 30px
  background-color: #fff
  border-radius: 2px
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33)
  transition: all 0.3s ease
  font-family: Helvetica, Arial, sans-serif
  display: flex
  flex-direction: column
  align-items: center
  justify-content: space-between
label
  font-weight: 600
  margin-right: 20px

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter
  opacity: 0

.modal-leave-active
  opacity: 0

.modal-enter .password-modal-container,
.modal-leave-active .password-modal-container
  -webkit-transform: scale(1.1)
  transform: scale(1.1)
</style>