var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('transition',{attrs:{"name":"modal"}},[_c('div',{staticClass:"modal-mask"},[_c('div',{staticClass:"modal-wrapper"},[_c('v-form',{ref:"form",staticClass:"w-100"},[_c('div',{staticClass:"edit-modal-container"},[_c('div',{staticClass:"row d-flex justify-center align-center w-100"},[_c('h2',[_vm._v("Edit "+_vm._s(_vm.field.name)+" field")])]),(_vm.specialForm)?_c('div',{staticClass:"col"},[_c('div',{staticClass:"row d-flex justify-center align-center mt-5 mb-5"},[_c('label',{attrs:{"for":""}},[_vm._v(" New "+_vm._s(_vm.field.name)+": ")]),(_vm.field.name == 'Phone Number')?_c('v-text-field',{attrs:{"label":_vm.field.name,"rules":[_vm.rules.required]},model:{value:(_vm.newContentField),callback:function ($$v) {_vm.newContentField=$$v},expression:"newContentField"}}):_vm._e(),(_vm.field.name == 'Email')?_c('v-text-field',{attrs:{"label":_vm.field.name,"rules":[_vm.rules.required, _vm.rules.email]},model:{value:(_vm.newContentField),callback:function ($$v) {_vm.newContentField=$$v},expression:"newContentField"}}):_vm._e()],1),_c('div',{staticClass:"row d-flex justify-center align-center mt-5 mb-5"},[_c('v-btn',{attrs:{"outlined":"","color":"primary","dark":""},on:{"click":_vm.generateConfirmationCode}},[_vm._v("Generate confirmation code ")])],1),(_vm.generationCode)?_c('div',{staticClass:"row d-flex justify-center align-center mt-5 mb-5"},[_c('label',{attrs:{"for":""}},[_vm._v(" Confirmation Code: ")]),_c('v-text-field',{attrs:{"label":"Confirmation Code","rules":[_vm.rules.required]},model:{value:(_vm.confirmationCode),callback:function ($$v) {_vm.confirmationCode=$$v},expression:"confirmationCode"}})],1):_vm._e()]):_vm._e(),(!_vm.specialForm)?_c('div',{staticClass:"row d-flex justify-center align-center mt-5 mb-5"},[_c('label',{attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.field.name)+": ")]),(
                  _vm.field.name == 'Name' ||
                  _vm.field.name == 'Last Name' ||
                  _vm.field.name == 'Position In The Organization'
                )?_c('v-text-field',{attrs:{"label":_vm.field.name,"rules":[_vm.rules.required]},model:{value:(_vm.newContentField),callback:function ($$v) {_vm.newContentField=$$v},expression:"newContentField"}}):_vm._e(),(_vm.field.name == 'Time Zone')?_c('v-autocomplete',{attrs:{"items":_vm.timeZones,"label":"Time zone","rules":[_vm.rules.required]},model:{value:(_vm.newContentField),callback:function ($$v) {_vm.newContentField=$$v},expression:"newContentField"}}):_vm._e(),(_vm.field.name == 'Role')?_c('v-select',{attrs:{"item-value":"id","item-text":"name","items":_vm.roles,"label":"Roles","rules":[_vm.rules.required]},model:{value:(_vm.newContentField),callback:function ($$v) {_vm.newContentField=$$v},expression:"newContentField"}}):_vm._e(),(_vm.field.name == 'Condition')?_c('v-switch',{attrs:{"label":`${
                  _vm.newContentField ? 'User Enabled' : 'User Disabled'
                } `},model:{value:(_vm.newContentField),callback:function ($$v) {_vm.newContentField=$$v},expression:"newContentField"}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"row d-flex justify-end align-end w-100"},[_c('v-btn',{staticClass:"m-2",attrs:{"color":"error","dark":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Cancel ")]),(!_vm.specialForm)?_c('v-btn',{staticClass:"m-2",attrs:{"color":"primary","dark":"","elevation":"2"},on:{"click":function($event){return _vm.handleSubmit()}}},[_vm._v(" Save ")]):_vm._e(),(_vm.specialForm)?_c('v-btn',{staticClass:"m-2",attrs:{"color":"primary","disabled":!_vm.generationCode,"dark":_vm.generationCode,"elevation":"2"},on:{"click":function($event){return _vm.handleSubmit()}}},[_vm._v(" Save ")]):_vm._e()],1)])])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }